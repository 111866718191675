import dayjs from 'dayjs';

const PluginDAYJSFORMATASKEY = (option, dayjsClass) => {
  // eslint-disable-next-line no-param-reassign
  dayjsClass.prototype.formatAsKey = function () {
    return this.format('YYYYMMDDTHHmmss,SSS');
  };
};

dayjs.extend(PluginDAYJSFORMATASKEY);

export default dayjs;
